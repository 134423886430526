import "./App.css";
import { FiWifi } from "react-icons/fi";

function App() {
  const handleClick = () => {
    window.location =
      "http://192.168.60.254:880/cgi-bin/hotspot_login.cgi" +
      "?" +
      window.location.search.substr(1);
  };

  return (
    <div className="App">
      <h1>Svečani salon "Krin"</h1>
      <div className="text">
        <h2>Ugodno druženje, žele vam:</h2>
        <h2>Marina & Marko</h2>
        <h3>01.06.2022.</h3>
      </div>
      <FiWifi onClick={() => handleClick()} className="wifi" color={"white"} />
    </div>
  );
}

export default App;
